export const testimonialList = [
  {
    id: 1,
    name: 'Oliver G',
    testimonial: 'This App has helped a small company transition to a larger more efficient company. All of our HR needs have been met with WebHR and they are continuing to improve it. We started using when we were 45 employees. Today we are over 235 employees and our people management and real time tracking has been so helpful as we make this transition.',
    color: '#E1FEFF',
  },
  {
    id: 2,
    name: 'Accalia B',
    testimonial: 'Excellent HR Software. Having all employee information at the click of a button, all in one location has been essential to streamlining daily processes.',
    color: '#FFECEB',
  },
  {
    id: 3,
    name: 'Meiraj H',
    testimonial: 'Excellent team and support, customizable and great report features. Scaleable, cost effective, feature rich and friendly user interface. HR self service is first class. A great marketing tool for HR department creating openness, easier communication with employees and enabling employees to procure HR services easily and fast.',
    color: '#FFF8EB',
  },
  {
    id: 4,
    name: 'Suzy H',
    testimonial: 'Great timely statistics, extract options of data, automated organization chart. Excellent support when the technical team is involved.',
    color: '#E2F7F8',
  },
  {
    id: 5,
    name: 'Everett B',
    testimonial: 'This is the ANSWER you have been looking for! WebHR is THE premier HR solution if your companies needs call for a multitude of tasks that have to be accomplished daily.',
    color: '#E0FFEB',
  },
  {
    id: 6,
    name: 'Nicola T',
    testimonial: 'It is easy to use and has on line tutorials for additional support. The overall layout is attractive and not complicated on the eyes. It is also well organized in the specific areas.',
    color: '#F8EEFF',
  },
  {
    id: 7,
    name: 'Kevin M',
    testimonial: 'WebHR is great. WebHR is easy to use, has good customer support and they are very responsive to our needs. They even have been very flexible with making custom program changes.',
    color: '#E2F7F8',
  },
  {
    id: 8,
    name: 'Dominic Kallas',
    testimonial: 'WebHR is a great tool for global companies. Easy to use employee management in multiple languages and an intuitive employee information database. While other tools might be better for a US-only employee base (ex: BambooHR), WebHR is the most cost-effective and adaptable solution for global teams. (Industry - Esports/Gaming)',
    color: '#E1FEFF',
  },
  {
    id: 9,
    name: 'Mike Carroll',
    testimonial: 'WebHR has excellent customer service. The Live Chat function has helped me many times. HopeWay has been using WebHR for almost 3 years, and I am very happy with the overall application and the service.',
    color: '#FFECEB',
  },
  {
    id: 10,
    name: 'Rania Al Saleh',
    testimonial: 'Friendly system My experience with WEBHR is great, it’s an easy system to use and well organized also very good support.',
    color: '#E1FEFF',
  },
  {
    id: 11,
    name: 'Lois M. G',
    testimonial: 'Simplifies HR management" I like that it has ample functions to facilitate the management of human resources, create lists of employees payment is very easy, another benefit is that it is based on the cloud, this allows to have access to the information from any place and device, has a good system security and information is well protected, another advantage is its low cost, this makes it affordable for any company.',
    color: '#E0FFEB',
  },
  {
    id: 12,
    name: 'Shaun J',
    testimonial: 'Excellent platform for EHR" WebHR is a simple and efficient way to manage the simple HR needs of any organization leaving HR available to manage the real issues.',
    color: '#E1FEFF',
  },
  {
    id: 13,
    name: 'Maria A',
    color: '#FFECEB',
    testimonial: "WebHR was the best human resource management mechanism that our company incorporate in our system with least cost .We had tried other software before but they didn't work to our expectations. WebHR is simple to set up and have a user friendly interface that is easy to adopt.",
  },
  {
    id: 14,
    name: 'Venkata SaiTeja',
    testimonial: 'Previously i used to rely on many third party apps to make track of the employees. But this web-HR made my life easier since it has all the features which are required for a hr in order to track the employees. Great app this minimizes my work effort a lot.',
    color: '#FFF8EB',
  },
  {
    id: 15,
    name: 'Shaun J',
    testimonial: 'WebHR is a simple and efficient way to manage the simple HR needs of any organization leaving HR available to manage the real issues.',
    color: '#E0FFEB',
  },
  {
    id: 16,
    name: 'Mohammed K',
    testimonial: 'Seamless, Mind-blowing features, Easy setup, User friendly, Flexible customization, Super-fast Technical Support.',
    color: '#F8EEFF',
  },
  {
    id: 17,
    name: 'Adam V',
    testimonial: 'Easy to use. Great features. I was looking for a cloud based system that would augment my new company as it grew and WebHR filled that bill.',
    color: '#F1F9FF',
  },
  {
    id: 18,
    name: 'Dr. Yameen M',
    testimonial: 'We have been using WebHR for our company MDC for over a year now - and we are quite satisfied with the way it shows us all of our HR analytics in one place. It made our lives easy by tracking all of our employees regardless of their physical location.',
    color: '#E2F7F8',
  },
  {
    id: 19,
    name: 'Neal B',
    testimonial: 'The best aspect by far is the sheer amount of features available, including a multitude of reports, recruiting/jobs portal, easy to manage attendance sheets and the option to use one software to manage multiple companies and locations.',
    color: '#E1FEFF',
  },
  {
    id: 20,
    name: 'Ejaz M',
    testimonial: 'WebHR can be easily be implemented in any Company as a stand alone HR solution covering all the basic needs of any HR department. Once you become an extensive user, you need enhancement in certain features, the best part I like about the product is, that there is a dedicated development team available at your disposal which can deliver these enhancements.',
    color: '#FFECEB',
  },
  {
    id: 21,
    name: 'Ashley F',
    testimonial: 'WebHR helped the whole organisation to work more as a community. It has made applications easier and able to disseminate information quicker. Simplified interface and very user friendly.',
    color: '#FFF8EB',
  },
  {
    id: 22,
    name: 'Oliver G',
    testimonial: 'All of our HR needs have been met with WebHR. We started using when we were 45 employees. Today we are over 235 employees and our people management and real time tracking has been so helpful as we make this transition.',
    color: '#E0FFEB',
  },
  {
    id: 23,
    name: 'Naureen S.',
    testimonial: 'I have been using this software for three years, it is very easy to use, save lots of time of personnel management and above all, the attendance and payslip generation module are the most useful of all because of its auto-generation feature. The reports of each and every module help administrator and HR personnel keep management updated easily and within no time.',
    color: '#F8EEFF',
  },
  {
    id: 24,
    name: 'Doug K.',
    testimonial: "WebHR is the one solution I found that was affordable, comprehensive, and was flexible to our company's size. The program is very customizable, covers pretty much any task or data you can think of, and generates excellent reports and summaries.",
    color: '#F1F9FF',
  },
  {
    id: 25,
    name: 'Elizah S.',
    testimonial: 'est thing is that the WebHR team is very helpful, friendly, cooperative and supportive. Their response time to any of my queries is quick and they never leave you hanging. In fact, some times they go out of their way to give support.',
    color: '#E2F7F8',
  },
  {
    id: 26,
    name: 'Matt G.',
    testimonial: 'I came across this in the early stages of my business. I see it as a valuable resource for all the tools it offers. The most awesome part is the recruiting portal. I love the ease of use of the recruiting portal. I love the job portal that can be used on my website. Very professional software! I recommend it!',
    color: '#E1FEFF',
  },
  {
    id: 27,
    name: 'Kendra K.',
    testimonial: 'As a small-medium business, WebHR offered all the features we were looking for at the best price. The software was easy to learn, and has so much adaptability to fit your exact needs. I love the customization for employee access and access templates to allow me to give each employee just what they need.',
    color: '#FFF8EB',
  },
  {
    id: 28,
    name: 'Gowtham K.',
    testimonial: 'I have a good experience with this WebHR Software, i have been using this software for 2 years, Its Really good user friendly software, easy way to use and save the lot of time and save the papers also. Easy way to maintain the payroll and payslips . Overall its a good software, i will suggest everyone.',
    color: '#E0FFEB',
  },
  {
    id: 29,
    name: 'Syed Sajjad H.',
    testimonial: 'Overall its a great tool which is of convenient for employees for handling their, Performance Evaluations, Knowledge Pools, Different Polls, Leaves Management and Most of All to connect with each other digitally.',
    color: '#F8EEFF',
  },
  {
    id: 30,
    name: 'Christian H.',
    testimonial: "This is the best HR software I have ever personally used. It's so simple to use, great features, easy to use (for employees) quick to clock in and out, great payroll system and reporting. Support is second to none! So helpful when you need help. I would highly recommend anyone to use Web HR.",
    color: '#F1F9FF',
  },
  {
    id: 31,
    name: 'Nilanthi H.',
    testimonial: 'The best thing about WebHR is the ease at getting to know the Daily Dose of Information with just a few clicks . Any industry can make use of this software to connect and share knowledge or ideas with each other so "Team work makes the Dream work" . Moreover its the ability to mutualise on this platform that makes us the happiest users.',
    color: '#E2F7F8',
  },
  {
    id: 32,
    name: 'Melroy D.',
    testimonial: 'WebHR has been like a saviour for our company in maintianing records and to get rid of all the manual systems. Its the best application with an awesome support team.',
    color: '#E1FEFF',
  },
  {
    id: 33,
    name: 'Hennadii K.',
    testimonial: "It's really simple and don't need to additional skills for use it. Any employee in our company can very easily use this program, even people who use a computer only for surfing the Internet. Cute interface and excellent speed allow you to avoid unnecessary bureaucracy when interacting with colleagues from other departments or approving documents.",
    color: '#FFECEB',
  },
  {
    id: 34,
    name: 'Rijo V.',
    testimonial: 'WebHR is very interactive, smooth and nullifies the effort of an employee to use the features provided by the HR management. I like WebHR skill and suggested my management to leverage Alexa skills to office employees for their tension free interaction and usage of HR services.',
    color: '#FFF8EB',
  },
  {
    id: 35,
    name: 'Kelly M.',
    testimonial: 'I work in a pharmaceutical company for 10 years as HR manager. Our company is based on 2000 plus employees, and we have been using WebHR from Last two years. As our company is growing day by day. We have to be more updated with technology. And YES WebHR does solve our problem. It is Very easy to operate and very time Effective.',
    color: '#E0FFEB',
  },
  {
    id: 36,
    name: 'Kelly M',
    testimonial: "WebHR just surprised me with this NEW INTEGRATION OF ECHO DOT I have bought ECHO DOT two devices like 3 months ago. One for myself to control all my employees attendance, leaves, employee's birthdays, and upcoming training and much more. And other Device for my CEO because just love the idea of ECHO DOT.",
    color: '#F1F9FF',
  },
  {
    id: 37,
    name: 'Ali Z.',
    testimonial: 'WebHR is a friendly software which keep us updated, What is happening around with regard to employee attendance / time keeping and keep the data accessible by one click as and when required. Best Part is its interface and employee statistical dashboard!',
    color: '#F8EEFF',
  },
  {
    id: 38,
    name: 'Parkash K.',
    testimonial: "We are using WebHR since last three years, practically, we found it very useful in all aspects of HR management, it's features and reporting structure enable our core team to have a look on the entire day to day business by a single click. It's cloud based and can be use in any corner of world.",
    color: '#E2F7F8',
  },
  {
    id: 39,
    name: 'Maria T.',
    testimonial: 'WebHR solved the problems of communications between HR and staff as it serves as a platform for HR improved procedures, new polices, news about the company and staff. Benefits - will provide/communicate to us standard operational procedures, open communication, improved HR procedures and less paper works.',
    color: '#E1FEFF',
  },
  {
    id: 40,
    name: 'Md Masud R.',
    testimonial: "WebHR is fully organised this system. Previous time i use a Long book where i lost my most of time to keep worker attendance, Employement etc more. But when i start WebHR, It's no make my all work so easy. All automatically.",
    color: '#FFECEB',
  },
  {
    id: 41,
    name: 'Christopher H.',
    testimonial: 'As a single user with no hr experience I was able to use this software for my domestic employees with out any hassles. The support was real time, fast and efficient. I have been very impressed.',
    color: '#F8EEFF',
  },
  {
    id: 42,
    name: 'Kris R.',
    testimonial: 'We have a number of projects with different characteristics and WebHR is flexible enough to be applied across them all.',
    color: '#FFF8EB',
  },
]
